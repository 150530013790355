import { Container, Section } from "components/animated";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import React, { PureComponent } from "react";
import { animatedChild, animatedContainer } from "utils/commonPoses";
import posed, { PoseGroup } from "react-pose";

import Swipeable from "react-swipeable";
import Waypoint from "react-waypoint";
import classNames from "classnames/bind";
import { easing } from "popmotion";
import { renderFluidImage } from "utils/imageHelpers";
import { sine } from "utils/easing";
import styles from "./Gallery.module.scss";
import uid from "utils/uid";

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedContainer = posed(Container)(animatedChild);
// const ImageReveal = posed.figure(animatedReveal);
const AnimatedImage = posed.figure({
  preEnter: { opacity: 0, x: ({ dir }) => (dir === 1 ? `95%` : `-95%`) },
  enter: {
    applyAtStart: { opacity: 0, x: ({ dir }) => (dir === 1 ? `95%` : `-95%`) },
    opacity: 1,
    x: 0,
    transition: {
      x: { type: "tween", ease: sine.out, duration: 300 },
      opacity: { type: "tween", ease: sine.out, duration: 200 },
    },
  },
  exit: {
    opacity: 0,
    x: ({ dir }) => (dir === 1 ? `-100%` : `100%`),
    transition: {
      x: { type: "tween", ease: sine.in, duration: 300 },
      opacity: { type: "tween", ease: sine.in, duration: 200 },
    },
  },
});

const AnimatedButton = posed.button({
  hidden: {
    zIndex: 0,
    x: ({ dir }) => (dir === "left" ? `50%` : `-50%`),
  },
  visible: {
    x: ({ dir }) => (dir === "left" ? `-50%` : `50%`),
    delay: 500,
    transition: {
      x: ({ from, to, dir }) => ({
        duration: 800,
        type: "keyframes",
        values: [from, dir === "left" ? `-100%` : `100%`, to],
        times: [0, 0.5, 1],
        easings: [easing.circIn, easing.circOut],
      }),
    },
  },
});

const AnimatedControls = posed.fieldset({
  hidden: {
    zIndex: 0,
  },
  visible: {
    zIndex: 2,
    delay: 500,
    transition: {
      zIndex: ({ from, to }) => ({
        duration: 800,
        type: "keyframes",
        values: [from, to, to],
        times: [0, 0.5, 1],
      }),
    },
  },
});

export default class Gallery extends PureComponent {
  first = true;

  constructor(props) {
    super(props);
    this.id = `gallery-${uid()}`;

    this.state = {
      index: 0,
      dir: 1,
      visible: false,
    };
  }

  onEnter = () => {
    this.setState({ visible: true });
  };

  componentDidMount() {
    setTimeout(() => {
      this.first = false;
    });
  }

  next = () => {
    let index =
      this.state.index === this.props.items.length - 1
        ? 0
        : this.state.index + 1;
    this.setState({ dir: 1 }, () => {
      this.setState({ index });
    });
  };

  prev = () => {
    let index =
      this.state.index === 0
        ? this.props.items.length - 1
        : this.state.index - 1;

    this.setState({ dir: -1 }, () => {
      this.setState({ index });
    });
  };

  render() {
    let { primary, prevTheme } = this.props;
    const { theme } = primary;
    const currentItem = this.props.items[this.state.index];
    const prevItem = this.props.items[
      this.state.index === 0
        ? this.props.items.length - 1
        : this.state.index - 1
    ];
    const nextItem = this.props.items[
      this.state.index === this.props.items.length - 1
        ? 0
        : this.state.index + 1
    ];

    if (prevTheme.indexOf("white") >= 0) {
      prevTheme = "white";
    }

    return (
      <Waypoint
        scrollableAncestor={typeof window === "undefined" ? null : window}
        onEnter={this.onEnter}
        topOffset={`25%`}
        bottomOffset={`25%`}
      >
        <AnimatedSection
          initialPose="hidden"
          pose={this.state.visible ? "visible" : "hidden"}
          className={`is-theme-${theme} ${cx({
            section: true,
            [theme]: true,
          })}`}
        >
          <figure className={cx({ pre: true, [prevTheme]: true })} />

          <AnimatedContainer>
            <Swipeable
              trackMouse
              onSwipedLeft={this.next}
              onSwipedRight={this.prev}
              className={cx({ container: true })}
            >
              <PoseGroup preEnterPose="preEnter">
                {prevItem && (
                  <figure
                    className={styles.prerender}
                    key={`${this.id}-prerenderitem-${this.state.index - 1}`}
                  >
                    {renderFluidImage(prevItem.image, null, {
                      fadeIn: false,
                      loading: `eager`,
                    })}
                  </figure>
                )}
                <AnimatedImage
                  className={styles.image}
                  initialPose={this.first ? "enter" : "preEnter"}
                  key={`${this.id}-item-${this.state.index}`}
                  dir={this.state.dir}
                >
                  {renderFluidImage(currentItem.image, null, {
                    fadeIn: true,
                    loading: `eager`,
                  })}
                </AnimatedImage>
                {nextItem && (
                  <figure
                    className={styles.prerender}
                    key={`${this.id}-prerenderitem-${this.state.index + 1}`}
                  >
                    {renderFluidImage(nextItem.image, null, {
                      fadeIn: false,
                      loading: `eager`,
                    })}
                  </figure>
                )}
              </PoseGroup>
              {/* <ImageReveal className={cx({ imageReveal: true })} /> */}
            </Swipeable>
            <AnimatedControls
              withParent={false}
              initialPose="hidden"
              pose={this.state.visible ? "visible" : "hidden"}
              className={styles.controls}
              aria-label="gallery buttons"
              aria-controls={this.id}
            >
              <AnimatedButton
                withParent={false}
                initialPose="hidden"
                dir="left"
                pose={this.state.visible ? "visible" : "hidden"}
                className={cx({ button: true, prev: true })}
                onClick={this.prev}
              >
                <span className="is-sr-only">Prev</span>
                <i className="icon">
                  <FaChevronLeft />
                </i>
              </AnimatedButton>
              <AnimatedButton
                withParent={false}
                initialPose="hidden"
                dir="right"
                pose={this.state.visible ? "visible" : "hidden"}
                className={cx({ button: true, next: true })}
                onClick={this.next}
              >
                <span className="is-sr-only">Next</span>
                <i className="icon">
                  <FaChevronRight />
                </i>
              </AnimatedButton>
            </AnimatedControls>
          </AnimatedContainer>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
