import { Column, Section } from "components/animated";
import React, { PureComponent } from "react";
import {
  animatedChild,
  animatedContainer,
  animatedReveal,
} from "utils/commonPoses";

import { CgArrowLongRight } from "react-icons/cg";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Link from "gatsby-link";
import Waypoint from "react-waypoint";
import classNames from "classnames/bind";
import posed from "react-pose";
import { renderFluidImage } from "utils/imageHelpers";
import styles from "./BigCallout.module.scss";
import { validateText } from "utils/renderHelpers";

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedColumn = posed(Column)(animatedChild);
const AnimatedHeading = posed.h3(animatedChild);
const AnimatedLink = posed.div(animatedChild);
const ImageReveal = posed.figure(animatedReveal);

const cx = classNames.bind(styles);

export default class BigCallout extends PureComponent {
  state = { visible: false };

  onEnter = () => {
    this.setState({ visible: true });
  };

  render() {
    const { primary } = this.props;
    const { heading } = primary;
    const hasImage = primary.image && primary.image.url ? true : false;
    const doc = primary.link.document[0];

    return (
      <Waypoint
        key={"bigCallout"}
        scrollableAncestor={typeof window === "undefined" ? null : window}
        onEnter={this.onEnter}
        topOffset={`20%`}
        bottomOffset={`20%`}
      >
        <AnimatedSection
          initialPose="hidden"
          pose={this.state.visible ? "visible" : "hidden"}
          stagger={250}
          className={cx({
            callout: true,
            [primary.theme]: true,
            textOnly: hasImage === false,
          })}
        >
          {hasImage ? (
            <Container>
              <Columns mobile multiline>
                <Columns.Column
                  className={`is-flex-tablet is-order-2-mobile is-order-1-tablet ${styles.contentColumn}`}
                  mobile={{ size: 12 }}
                  tablet={{ size: 5 }}
                >
                  {validateText && (
                    <AnimatedHeading>{heading.text}</AnimatedHeading>
                  )}
                  <AnimatedLink>
                    <Link
                      className={styles.button}
                      to={`/${doc.slug}`}
                      title={doc.title}
                    >
                      <span>
                        {primary.button_label || primary.link.document.title}
                      </span>
                      <i className="icon">
                        <CgArrowLongRight />
                      </i>
                    </Link>
                  </AnimatedLink>
                </Columns.Column>
                <Columns.Column
                  className={"is-order-1-mobile is-order-2-tablet"}
                  mobile={{ size: 12 }}
                  tablet={{ size: 6, offset: 1 }}
                >
                  <figure>{renderFluidImage(primary.image)}</figure>
                  <ImageReveal
                    className={cx({ imageReveal: true, [primary.theme]: true })}
                  />
                </Columns.Column>
              </Columns>
            </Container>
          ) : (
            <Container>
              <Columns mobile multiline>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 9 }}
                  className={`is-flex-tablet ${styles.contentColumn}`}
                >
                  {validateText && (
                    <AnimatedHeading>{heading.text}</AnimatedHeading>
                  )}
                </Columns.Column>
                <AnimatedColumn
                  mobile={{ size: 12 }}
                  tablet={{ size: 3 }}
                  className={`is-flex-tablet ${styles.contentColumn}`}
                >
                  <Link
                    className={styles.button}
                    to={`/${doc.slug}`}
                    title={doc.title}
                  >
                    <span>
                      {primary.button_label || primary.link.document.title}
                    </span>
                    <i className="icon">
                      <CgArrowLongRight />
                    </i>
                  </Link>
                </AnimatedColumn>
              </Columns>
            </Container>
          )}
        </AnimatedSection>
      </Waypoint>
    );
  }
}
