import React, { Component } from "react";
// Components
import Helmet from "react-helmet";

import Hero from "slices/Hero";
import FullWidthVideo from "slices/FullWidthVideo";
import FeatureContent from "slices/FeatureContent";
import FeatureContentWithImage from "slices/FeatureContentWithImage";
import Gallery from "slices/Gallery";
import Quote from "slices/Quote";
import Stats from "slices/Stats";
import Collage from "slices/Collage";
import getPageMeta from "utils/getPageMeta";

// graphql
import { graphql } from "gatsby";
import BigCallout from "slices/BigCallout";

class CaseStudyPage extends Component {
  renderSlice = (slice, prevSlice, nextSlice) => {
    if (!slice) {
      return null;
    }
    let Cmp;
    switch (slice.slice_type) {
      case "hero":
        slice.compressed = false;
        slice.hasAnimated = false;
        slice.animationDelay = 0;
        slice.clientName = this.props.data.page.data.client.document[0].data.name.text;
        slice.primary.theme = "red";
        Cmp = Hero;
        break;
      case "full_width_video":
        Cmp = FullWidthVideo;
        break;
      case "feature_content":
        Cmp = FeatureContent;
        break;
      case "feature_content_with_image":
        Cmp = FeatureContentWithImage;
        break;
      case "gallery":
        slice.prevTheme =
          prevSlice && prevSlice.primary.theme ? prevSlice.primary.theme : null;
        Cmp = Gallery;
        break;
      case "quote":
        Cmp = Quote;
        break;
      case "collage":
        Cmp = Collage;
        break;
      case "stats":
        Cmp = Stats;
        break;
      default:
        Cmp = null;
        break;
    }
    if (!Cmp) {
      return null;
    }
    return <Cmp {...slice} key={slice.id} />;
  };

  findNextCase(cases, uid) {
    let currentCaseIndex = 0;
    let nextCaseIndex = 0;
    for (let i = 0; i < cases.edges.length; i++) {
      let c = cases.edges[i].node;
      if (c.uid === uid) {
        currentCaseIndex = i;
        break;
      }
    }
    if (currentCaseIndex === cases.edges.length - 1) {
      nextCaseIndex = 0;
    } else {
      nextCaseIndex = currentCaseIndex + 1;
    }
    let result = cases.edges[nextCaseIndex].node;
    return result;
  }

  render() {
    // Set the document object
    const { page } = this.props.data;
    const { title, body } = page.data;
    const { caseStudies } = this.props.data;
    const nextCase = this.findNextCase(caseStudies, page.uid);

    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Work | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body.map((slice, idx) => {
          return this.renderSlice(slice, body[idx - 1], body[idx + 1]);
        })}
        <BigCallout
          primary={{
            link: {
              document: [
                {
                  slug: nextCase.slug,
                  uid: nextCase.uid,
                  data: nextCase.data
                }
              ]
            },
            theme: "red",
            heading: nextCase.data.title,
            image: nextCase.data.body[0].primary.image.nextcase,
            button_label: "See case"
          }}
        />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicCaseStudy(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url

            dimensions {
              width
              height
            }
          }
          facebook {
            url

            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        title {
          text
        }
        client {
          document {
            data {
              name {
                text
              }
            }
          }
        }
        body {
          ... on PrismicCaseStudyBodyHero {
            id
            slice_type
            primary {
              youtube_embed {
                embed_url
                width
                height
              }
              loop_start_time
              loop_end_time
              heading {
                text
                html
              }
              subheading {
                text
                html
              }
              paragraph {
                html
              }
              image {
                url
                dimensions {
                  width
                  height
                }

                nextcase {
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
          ... on PrismicCaseStudyBodyFullWidthVideo {
            id
            slice_type
            primary {
              embed {
                provider_name
                html
                type
                title
                embed_url
                width
                height
                thumbnail_url
              }
            }
          }
          ... on PrismicCaseStudyBodyFeatureContent {
            id
            slice_type
            primary {
              theme
              layout
              small_heading {
                text
              }
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyFeatureContentWithImage {
            id
            slice_type
            primary {
              theme
              orientation
              image_animation
              small_heading {
                text
              }
              tagline {
                text
                html
              }
              paragraph {
                text
                html
              }
            }
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCaseStudyBodyGallery {
            id
            slice_type
            primary {
              theme
            }
            items {
              caption {
                html
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCaseStudyBodyQuote {
            id
            slice_type
            primary {
              theme
              content {
                html
              }
              source {
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicCaseStudyBodyStats {
            id
            slice_type
            primary {
              theme
            }
            items {
              heading {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyCollage {
            id
            slice_type
            primary {
              theme
            }
            items {
              collage_image {
                url
                dimensions {
                  width
                  height
                }

                big {
                  url
                  dimensions {
                    width
                    height
                  }
                }
                small {
                  url
                  dimensions {
                    width
                    height
                  }
                }
                thumbnail {
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
    caseStudies: allPrismicCaseStudy(
      filter: { uid: { regex: "/^((?!_template).)*$/" } }
      sort: { fields: [data___title___text] }
    ) {
      edges {
        node {
          uid
          slug
          data {
            title {
              text
            }
            body {
              ... on PrismicCaseStudyBodyHero {
                id
                slice_type
                primary {
                  youtube_embed {
                    embed_url
                    width
                    height
                  }
                  loop_start_time
                  loop_end_time
                  heading {
                    text
                    html
                  }
                  subheading {
                    text
                    html
                  }
                  paragraph {
                    html
                  }
                  image {
                    url
                    dimensions {
                      width
                      height
                    }

                    nextcase {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CaseStudyPage;
