import React from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import { renderHtml, renderText } from 'utils/renderHelpers';
import { renderFluidImage } from 'utils/imageHelpers';

import classNames from 'classnames/bind';
import styles from './Quote.module.scss';
const cx = classNames.bind(styles);

export default ({ id, primary }) => (
  <Section className={cx({ quote: true, [primary.theme]: true })}>
    <Container>
      <Columns mobile multiline>
        <Columns.Column
          mobile={{ size: 12 }}
          tablet={{ size: primary.image.url ? 5 : 8, offset: 1 }}
          className={`is-paddingless${
            primary.image.url ? ` is-flex-tablet` : ''
          }
          ${cx({ contentWrapper: true, [primary.theme]: true })}`}
        >
          {renderHtml(
            primary.content,
            'blockquote',
            cx({
              content: true,
              [primary.image.url ? `half` : `full`]: true,
              [primary.theme]: true
            })
          )}
          {renderText(primary.source, 'div', styles.source)}
        </Columns.Column>
        {primary.image.url && (
          <Columns.Column
            mobile={{ size: 12 }}
            tablet={{ size: 6 }}
            className="is-paddingless"
          >
            <figure className={styles.image}>
              {renderFluidImage(primary.image)}
            </figure>
          </Columns.Column>
        )}
      </Columns>
    </Container>
  </Section>
);
