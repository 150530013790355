import React, { PureComponent } from 'react';
import { Section } from 'components/animated';
import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import { validateText, validateHtml } from 'utils/renderHelpers';
import { animatedContainer, animatedChild } from 'utils/commonPoses';
import classNames from 'classnames/bind';
import styles from './Stats.module.scss';
import Waypoint from 'react-waypoint';
import posed from 'react-pose';

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedHeading = posed.h3(animatedChild);
const AnimatedDescription = posed.div(animatedChild);

export default class Stats extends PureComponent {
  state = { visible: false };

  onEnter = () => {
    this.setState({ visible: true });
  };

  render() {
    const { primary, items } = this.props;

    return (
      <Waypoint
        scrollableAncestor={typeof window === 'undefined' ? null : window}
        onEnter={this.onEnter}
        topOffset={`30%`}
        bottomOffset={`30%`}
      >
        <AnimatedSection
          initialPose="hidden"
          pose={this.state.visible ? 'visible' : 'hidden'}
          className={`is-theme-${primary.theme} ${cx({
            section: true,
            [primary.theme]: true
          })}`}
        >
          <Container>
            <Columns mobile multiline>
              {items.map((item, idx) => (
                <Columns.Column
                  key={`stat-${idx}-${item.heading.text}`}
                  mobile={{ size: 6 }}
                  tablet={{ size: 3 }}
                >
                  <aside className={styles.stat}>
                    {validateText(item.heading) && (
                      <AnimatedHeading>{item.heading.text}</AnimatedHeading>
                    )}
                    {validateHtml(item.description) && (
                      <AnimatedDescription
                        dangerouslySetInnerHTML={{
                          __html: item.description.html
                        }}
                      />
                    )}
                  </aside>
                </Columns.Column>
              ))}
            </Columns>
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
